<template>
  <div class="insurance-certificate">
    <div class="insurance-certificate-form" v-if="selectedInsurance">
      <div class="input-container" v-if="selectedInsurance.conveyance === 'Ground or Rail'">
        <label for="carrier">Carrier <span>*</span></label>
        <RCInput
          class="input-container__input"
          :class="emptyFields['transportation_service'] === true ? 'missing-field' : ''"
          id="carrier"
          v-model="certificateForm.transportation_service"
          @input="
            assignValue('transportation_service', certificateForm.transportation_service),
              updateIndexedDB()
          "
          type="text"
        ></RCInput>
      </div>
      <div class="input-container" v-if="selectedInsurance.conveyance === 'Ocean'">
        <label for="carrier">Steamshipline<span>*</span></label>
        <RCInput
          class="input-container__input"
          :class="emptyFields['transportation_service'] === true ? 'missing-field' : ''"
          id="carrier"
          v-model="certificateForm.transportation_service"
          @input="
            assignValue('transportation_service', certificateForm.transportation_service),
              updateIndexedDB()
          "
          type="text"
        ></RCInput>
      </div>
      <div class="input-container" v-if="selectedInsurance.conveyance === 'Air'">
        <label for="carrier">Airline<span>*</span></label>
        <RCInput
          class="input-container__input"
          :class="emptyFields['transportation_service'] === true ? 'missing-field' : ''"
          id="carrier"
          v-model="certificateForm.transportation_service"
          @input="
            assignValue('transportation_service', certificateForm.transportation_service),
              updateIndexedDB()
          "
          type="text"
        ></RCInput>
      </div>
      <div class="input-container" v-if="selectedInsurance.conveyance === 'Ground or Rail'">
        <label for="bol">BOL #<span>*</span></label>
        <RCInput
          class="input-container__input"
          :class="emptyFields['transportation_service_id'] === true ? 'missing-field' : ''"
          id="bol"
          v-model="certificateForm.transportation_service_id"
          @input="
            assignValue('transportation_service_id', certificateForm.transportation_service_id),
              updateIndexedDB()
          "
          type="text"
        ></RCInput>
      </div>
      <div class="input-container" v-if="selectedInsurance.conveyance === 'Ocean'">
        <label for="bol">Vessel Name and Voyage #<span>*</span></label>
        <RCInput
          class="input-container__input"
          :class="emptyFields['transportation_service_id'] === true ? 'missing-field' : ''"
          id="bol"
          v-model="certificateForm.transportation_service_id"
          @input="
            assignValue('transportation_service_id', certificateForm.transportation_service_id),
              updateIndexedDB()
          "
          type="text"
        ></RCInput>
      </div>
      <div class="input-container" v-if="selectedInsurance.conveyance === 'Air'">
        <label for="bol">FLIGHT# or AWB #<span>*</span></label>
        <RCInput
          class="input-container__input"
          :class="emptyFields['transportation_service_id'] === true ? 'missing-field' : ''"
          id="bol"
          v-model="certificateForm.transportation_service_id"
          @input="
            assignValue('transportation_service_id', certificateForm.transportation_service_id),
              updateIndexedDB()
          "
          type="text"
        ></RCInput>
      </div>
      <div class="input-container">
        <SectionTitle
          :mandatory="false"
          :title="'Marks or Numbers'"
          :popoverData="{
            isPopover: true,
            title: 'Marks or Numbers',
            content: 'You can use the invoice# or a PO# that identifies the shipment',
            target: 'marks',
          }"
        ></SectionTitle>
        <RCInput
          class="input-container__input"
          id="marks"
          v-model="certificateForm.marksOrNumber"
          @input="assignValue('marks_or_numbers', certificateForm.marksOrNumber), updateIndexedDB()"
          type="text"
        ></RCInput>
      </div>
      <div class="input-container-date">
        <SectionTitle
          :mandatory="true"
          :title="'Sailing on/or About:'"
          :popoverData="{
            isPopover: true,
            title: 'Sailing on/or About',
            content: sailingMessage,
            target: 'date-picker',
          }"
        ></SectionTitle>
        <el-date-picker
          type="date"
          :class="emptyFields['sailingAbout'] === true ? 'missing-field' : ''"
          :placeholder="$t('stopsInfo.shippingDate')"
          :picker-options="datePickerOptions"
          v-model="certificateForm.sailingAbout"
          @input="assignValue('sailing_date', certificateForm.sailingAbout), updateIndexedDB()"
          id="date-picker"
          format="MM-dd-yyyy"
        >
        </el-date-picker>
        <div class="date-message-container" v-if="showDateMessage">
          <span class="date-message"> * Warranted no reported looses up today. </span>
          <br />
          <span class="date-message">
            * Warranted cargo is still on transit and has not arrived to final destination.
          </span>
        </div>
      </div>
      <div class="input-container">
        <SectionTitle
          :mandatory="false"
          :title="'Number Reference'"
          :popoverData="{
            isPopover: true,
            title: 'Number Reference',
            content: 'Reference or number for internal purposes only',
            target: 'number-reference',
          }"
        ></SectionTitle>
        <RCInput
          class="input-container__input"
          id="number-reference"
          v-model="certificateForm.reference_number"
          @input="
            assignValue('reference_number', certificateForm.reference_number), updateIndexedDB()
          "
          type="text"
        ></RCInput>
      </div>
    </div>
    <div class="aditional-information">
      <SectionTitle
        :mandatory="false"
        :title="'Loss payee'"
        id="loss-payee"
        :popoverData="{
          isPopover: true,
          title: 'Loss Payee',
          content:
            // eslint-disable-next-line max-len
            'The party to whom the claim from a loss is to be paid. Is the responsible for the cargo while in transit',
          target: 'loss-payee',
        }"
        class="title-section title-section--loss-payee"
      ></SectionTitle>
      <div class="aditional-information-form">
        <div class="input-container">
          <label for="name">Name <span>*</span></label>
          <RCInput
            class="input-container__input"
            :class="emptyFields['name'] === true ? 'missing-field' : ''"
            id="name"
            v-model="certificateForm.name"
            @input="
              assignValue('loss_payable', {
                name: certificateForm.name,
                address: certificateForm.address,
                location_info: certificateForm.stopInformation,
              }),
                updateIndexedDB()
            "
            type="text"
          ></RCInput>
        </div>
        <div class="input-container">
          <label for="address">Address <span>*</span></label>
          <RCInput
            class="input-container__input"
            :class="emptyFields['address'] === true ? 'missing-field' : ''"
            id="address"
            v-model="certificateForm.address"
            @input="
              assignValue('loss_payable', {
                name: certificateForm.name,
                address: certificateForm.address,
                location_info: certificateForm.stopInformation,
              }),
                updateIndexedDB()
            "
            type="text"
          ></RCInput>
        </div>
        <div class="input-container">
          <label for="stop">Country, City, State, ZC <span>*</span></label>
          <RCInput
            class="input-container__input"
            :class="emptyFields['stopInformation'] === true ? 'missing-field' : ''"
            id="stop"
            v-model="certificateForm.stopInformation"
            @input="
              assignValue('loss_payable', {
                name: certificateForm.name,
                address: certificateForm.address,
                location_info: certificateForm.stopInformation,
              }),
                updateIndexedDB()
            "
            type="text"
          ></RCInput>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters } from "vuex";
import RCInput from "rcat-components/RCInput";
import ValidateEmptyFields from "@/utils/validateEmptyFields";
import SectionTitle from "../components/SectionTitle.vue";

export default {
  name: "InsuranceCertificateForm",
  components: {
    RCInput,
    SectionTitle,
  },
  props: {
    selectedInsurance: Object,
  },
  data() {
    return {
      showDateMessage: false,
      sailingMessage:
        "This is the estimated dated of departure. if shipment already departured please note there will be a note on the certificate stating no known or reported looses as of today or date of issue of the certificate. if shipment already arrives at final destination then you can not add insurance to it",
      datePickerOptions: {
        disabledDate(time) {
          const targetDate = new Date(time);
          const oneWeekAgo = new Date();
          oneWeekAgo.setDate(oneWeekAgo.getDate() - 8);
          return targetDate < oneWeekAgo;
        },
      },
      certificateForm: {
        transportation_service: "",
        transportation_service_id: "",
        marksOrNumber: "",
        sailingAbout: "",
        reference_number: "",
        name: "",
        address: "",
        stopInformation: "",
        packing: "",
        type_of_shipment: "",
      },
      emptyFields: {
        transportation_service: false,
        transportation_service_id: false,
        sailingAbout: false,
        name: false,
        address: false,
        stopInformation: false,
        packing: false,
        type_of_shipment: false,
      },
    };
  },
  computed: {
    ...mapGetters({ insuranceCertificate: "insurance/getGenerateCertificateData" }),
    ...mapGetters({ triggerValidations: "insurance/getTriggerCertificateValidations" }),
  },
  watch: {
    insuranceCertificate: {
      immediate: true,
      deep: true,
      handler() {
        if (this.insuranceCertificate) {
          this.certificateForm.transportation_service =
            this.insuranceCertificate.transportation_service;
          this.certificateForm.transportation_service_id =
            this.insuranceCertificate.transportation_service_id;
          this.certificateForm.marksOrNumber = this.insuranceCertificate.marks_or_numbers;
          this.certificateForm.sailingAbout = this.insuranceCertificate.sailing_date;
          this.certificateForm.reference_number = this.insuranceCertificate.reference_number;
          this.certificateForm.name = this.insuranceCertificate.loss_payable.name;
          this.certificateForm.address = this.insuranceCertificate.loss_payable.address;
          this.certificateForm.stopInformation =
            this.insuranceCertificate.loss_payable.location_info;
        }
      },
    },
    triggerValidations(value) {
      if (value) {
        this.validateFields();
      }
    },
    "certificateForm.sailingAbout": {
      handler(date) {
        if (!date) {
          this.showDateMessage = false;
          return;
        }
        const isBeforeToday = this.isDateBeforeToday(date);
        if (isBeforeToday) {
          this.showDateMessage = true;
          return;
        }
        this.showDateMessage = false;
      },
    },
  },
  beforeDestroy() {
    this.$store.commit("insurance/resetInsuranceCertificate");
  },
  methods: {
    /* Validations Empty Fields */
    validateFields() {
      const validate = ValidateEmptyFields(this.certificateForm);
      this.resetEmptyFieldsValues();
      validate.emptyFields.forEach((field) => {
        this.emptyFields[field] = true;
      });
      return validate;
    },
    resetEmptyFieldsValues() {
      const keys = Object.keys(this.emptyFields);
      keys.forEach((key) => {
        this.emptyFields[key] = false;
      });
    },
    updateIndexedDB() {
      this.$emit("updateCollectionInIndexedDB");
    },
    assignValue(key, value) {
      this.$store.commit("insurance/updateGenerateCertificateProperty", {
        key,
        value,
      });
    },
    isDateBeforeToday(date) {
      const selectedDate = new Date(date);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return selectedDate < today;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/components/RCComponents.scss";

.insurance-certificate-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}
.input-container {
  label {
    text-align: start;
    width: 100%;
    font-size: 0.9rem;
    span {
      color: red;
    }
  }
  &__input {
    width: 100%;
    ::v-deep {
      border: 1px solid $color-border-container;
    }
  }
}
.date-message-container {
  text-align: start;
  font-size: 0.8rem;
  color: red;
}
::v-deep .rc-form-group {
  width: 100%;
}
::v-deep .section__title label {
  font-weight: normal;
}
@media (max-width: 810px) {
  .insurance-certificate-form {
    display: block;
  }

  .input-container {
    margin-bottom: 10px;
  }
  .input-container-date {
    margin-bottom: 10px;
  }
}
::v-deep {
  .el-input {
    width: 100%;
  }
  .el-input__inner {
    @include font-standart-text;
    border: 1px solid $color-border-container;
    border-radius: 12px;
  }
}
.aditional-information-form {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  text-align: start;
  .input-container {
    label {
      text-align: start;
      width: 100%;
      font-size: 0.9rem;
      span {
        color: red;
      }
    }
    &__input {
      width: 100%;
      ::v-deep {
        border: 1px solid $color-border-container;
      }
    }
  }
}
.aditional-information {
  text-align: start;
}
.title-section {
  margin: 10px 0px;
  display: inline-flex;
  color: $color-primary-trigger-button;
  &--loss-payee {
    margin: 20px 0px 0px 0px;
  }
}
::v-deep .section__title label {
  font-weight: normal;
}
::v-deep .rc-form-group {
  width: 100%;
}
@media (max-width: 810px) {
  .aditional-information-form {
    grid-template-columns: 1fr !important;
  }
}
.input-container .missing-field {
  background-color: $missing-fields;
}
::v-deep .missing-field .el-input__inner {
  background-color: $missing-fields !important;
}
</style>
