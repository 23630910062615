var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"insurance-certificate"},[(_vm.selectedInsurance)?_c('div',{staticClass:"insurance-certificate-form"},[(_vm.selectedInsurance.conveyance === 'Ground or Rail')?_c('div',{staticClass:"input-container"},[_vm._m(0),_c('RCInput',{staticClass:"input-container__input",class:_vm.emptyFields['transportation_service'] === true ? 'missing-field' : '',attrs:{"id":"carrier","type":"text"},on:{"input":function($event){_vm.assignValue('transportation_service', _vm.certificateForm.transportation_service),
            _vm.updateIndexedDB()}},model:{value:(_vm.certificateForm.transportation_service),callback:function ($$v) {_vm.$set(_vm.certificateForm, "transportation_service", $$v)},expression:"certificateForm.transportation_service"}})],1):_vm._e(),(_vm.selectedInsurance.conveyance === 'Ocean')?_c('div',{staticClass:"input-container"},[_vm._m(1),_c('RCInput',{staticClass:"input-container__input",class:_vm.emptyFields['transportation_service'] === true ? 'missing-field' : '',attrs:{"id":"carrier","type":"text"},on:{"input":function($event){_vm.assignValue('transportation_service', _vm.certificateForm.transportation_service),
            _vm.updateIndexedDB()}},model:{value:(_vm.certificateForm.transportation_service),callback:function ($$v) {_vm.$set(_vm.certificateForm, "transportation_service", $$v)},expression:"certificateForm.transportation_service"}})],1):_vm._e(),(_vm.selectedInsurance.conveyance === 'Air')?_c('div',{staticClass:"input-container"},[_vm._m(2),_c('RCInput',{staticClass:"input-container__input",class:_vm.emptyFields['transportation_service'] === true ? 'missing-field' : '',attrs:{"id":"carrier","type":"text"},on:{"input":function($event){_vm.assignValue('transportation_service', _vm.certificateForm.transportation_service),
            _vm.updateIndexedDB()}},model:{value:(_vm.certificateForm.transportation_service),callback:function ($$v) {_vm.$set(_vm.certificateForm, "transportation_service", $$v)},expression:"certificateForm.transportation_service"}})],1):_vm._e(),(_vm.selectedInsurance.conveyance === 'Ground or Rail')?_c('div',{staticClass:"input-container"},[_vm._m(3),_c('RCInput',{staticClass:"input-container__input",class:_vm.emptyFields['transportation_service_id'] === true ? 'missing-field' : '',attrs:{"id":"bol","type":"text"},on:{"input":function($event){_vm.assignValue('transportation_service_id', _vm.certificateForm.transportation_service_id),
            _vm.updateIndexedDB()}},model:{value:(_vm.certificateForm.transportation_service_id),callback:function ($$v) {_vm.$set(_vm.certificateForm, "transportation_service_id", $$v)},expression:"certificateForm.transportation_service_id"}})],1):_vm._e(),(_vm.selectedInsurance.conveyance === 'Ocean')?_c('div',{staticClass:"input-container"},[_vm._m(4),_c('RCInput',{staticClass:"input-container__input",class:_vm.emptyFields['transportation_service_id'] === true ? 'missing-field' : '',attrs:{"id":"bol","type":"text"},on:{"input":function($event){_vm.assignValue('transportation_service_id', _vm.certificateForm.transportation_service_id),
            _vm.updateIndexedDB()}},model:{value:(_vm.certificateForm.transportation_service_id),callback:function ($$v) {_vm.$set(_vm.certificateForm, "transportation_service_id", $$v)},expression:"certificateForm.transportation_service_id"}})],1):_vm._e(),(_vm.selectedInsurance.conveyance === 'Air')?_c('div',{staticClass:"input-container"},[_vm._m(5),_c('RCInput',{staticClass:"input-container__input",class:_vm.emptyFields['transportation_service_id'] === true ? 'missing-field' : '',attrs:{"id":"bol","type":"text"},on:{"input":function($event){_vm.assignValue('transportation_service_id', _vm.certificateForm.transportation_service_id),
            _vm.updateIndexedDB()}},model:{value:(_vm.certificateForm.transportation_service_id),callback:function ($$v) {_vm.$set(_vm.certificateForm, "transportation_service_id", $$v)},expression:"certificateForm.transportation_service_id"}})],1):_vm._e(),_c('div',{staticClass:"input-container"},[_c('SectionTitle',{attrs:{"mandatory":false,"title":'Marks or Numbers',"popoverData":{
          isPopover: true,
          title: 'Marks or Numbers',
          content: 'You can use the invoice# or a PO# that identifies the shipment',
          target: 'marks',
        }}}),_c('RCInput',{staticClass:"input-container__input",attrs:{"id":"marks","type":"text"},on:{"input":function($event){_vm.assignValue('marks_or_numbers', _vm.certificateForm.marksOrNumber), _vm.updateIndexedDB()}},model:{value:(_vm.certificateForm.marksOrNumber),callback:function ($$v) {_vm.$set(_vm.certificateForm, "marksOrNumber", $$v)},expression:"certificateForm.marksOrNumber"}})],1),_c('div',{staticClass:"input-container-date"},[_c('SectionTitle',{attrs:{"mandatory":true,"title":'Sailing on/or About:',"popoverData":{
          isPopover: true,
          title: 'Sailing on/or About',
          content: _vm.sailingMessage,
          target: 'date-picker',
        }}}),_c('el-date-picker',{class:_vm.emptyFields['sailingAbout'] === true ? 'missing-field' : '',attrs:{"type":"date","placeholder":_vm.$t('stopsInfo.shippingDate'),"picker-options":_vm.datePickerOptions,"id":"date-picker","format":"MM-dd-yyyy"},on:{"input":function($event){_vm.assignValue('sailing_date', _vm.certificateForm.sailingAbout), _vm.updateIndexedDB()}},model:{value:(_vm.certificateForm.sailingAbout),callback:function ($$v) {_vm.$set(_vm.certificateForm, "sailingAbout", $$v)},expression:"certificateForm.sailingAbout"}}),(_vm.showDateMessage)?_c('div',{staticClass:"date-message-container"},[_c('span',{staticClass:"date-message"},[_vm._v(" * Warranted no reported looses up today. ")]),_c('br'),_c('span',{staticClass:"date-message"},[_vm._v(" * Warranted cargo is still on transit and has not arrived to final destination. ")])]):_vm._e()],1),_c('div',{staticClass:"input-container"},[_c('SectionTitle',{attrs:{"mandatory":false,"title":'Number Reference',"popoverData":{
          isPopover: true,
          title: 'Number Reference',
          content: 'Reference or number for internal purposes only',
          target: 'number-reference',
        }}}),_c('RCInput',{staticClass:"input-container__input",attrs:{"id":"number-reference","type":"text"},on:{"input":function($event){_vm.assignValue('reference_number', _vm.certificateForm.reference_number), _vm.updateIndexedDB()}},model:{value:(_vm.certificateForm.reference_number),callback:function ($$v) {_vm.$set(_vm.certificateForm, "reference_number", $$v)},expression:"certificateForm.reference_number"}})],1)]):_vm._e(),_c('div',{staticClass:"aditional-information"},[_c('SectionTitle',{staticClass:"title-section title-section--loss-payee",attrs:{"mandatory":false,"title":'Loss payee',"id":"loss-payee","popoverData":{
        isPopover: true,
        title: 'Loss Payee',
        content:
          // eslint-disable-next-line max-len
          'The party to whom the claim from a loss is to be paid. Is the responsible for the cargo while in transit',
        target: 'loss-payee',
      }}}),_c('div',{staticClass:"aditional-information-form"},[_c('div',{staticClass:"input-container"},[_vm._m(6),_c('RCInput',{staticClass:"input-container__input",class:_vm.emptyFields['name'] === true ? 'missing-field' : '',attrs:{"id":"name","type":"text"},on:{"input":function($event){_vm.assignValue('loss_payable', {
              name: _vm.certificateForm.name,
              address: _vm.certificateForm.address,
              location_info: _vm.certificateForm.stopInformation,
            }),
              _vm.updateIndexedDB()}},model:{value:(_vm.certificateForm.name),callback:function ($$v) {_vm.$set(_vm.certificateForm, "name", $$v)},expression:"certificateForm.name"}})],1),_c('div',{staticClass:"input-container"},[_vm._m(7),_c('RCInput',{staticClass:"input-container__input",class:_vm.emptyFields['address'] === true ? 'missing-field' : '',attrs:{"id":"address","type":"text"},on:{"input":function($event){_vm.assignValue('loss_payable', {
              name: _vm.certificateForm.name,
              address: _vm.certificateForm.address,
              location_info: _vm.certificateForm.stopInformation,
            }),
              _vm.updateIndexedDB()}},model:{value:(_vm.certificateForm.address),callback:function ($$v) {_vm.$set(_vm.certificateForm, "address", $$v)},expression:"certificateForm.address"}})],1),_c('div',{staticClass:"input-container"},[_vm._m(8),_c('RCInput',{staticClass:"input-container__input",class:_vm.emptyFields['stopInformation'] === true ? 'missing-field' : '',attrs:{"id":"stop","type":"text"},on:{"input":function($event){_vm.assignValue('loss_payable', {
              name: _vm.certificateForm.name,
              address: _vm.certificateForm.address,
              location_info: _vm.certificateForm.stopInformation,
            }),
              _vm.updateIndexedDB()}},model:{value:(_vm.certificateForm.stopInformation),callback:function ($$v) {_vm.$set(_vm.certificateForm, "stopInformation", $$v)},expression:"certificateForm.stopInformation"}})],1)])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"carrier"}},[_vm._v("Carrier "),_c('span',[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"carrier"}},[_vm._v("Steamshipline"),_c('span',[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"carrier"}},[_vm._v("Airline"),_c('span',[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"bol"}},[_vm._v("BOL #"),_c('span',[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"bol"}},[_vm._v("Vessel Name and Voyage #"),_c('span',[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"bol"}},[_vm._v("FLIGHT# or AWB #"),_c('span',[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_vm._v("Name "),_c('span',[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"address"}},[_vm._v("Address "),_c('span',[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"stop"}},[_vm._v("Country, City, State, ZC "),_c('span',[_vm._v("*")])])
}]

export { render, staticRenderFns }